<template>
  <page style="height: 100vh; overflow: hidden">
    <template v-if="isScan && cabinet && !isBtns">
      <div slot="nav" class="page-head-default">
        <img src="../assets/imgs/menu.png" alt="" @click="isMenuView = true" />
        {{ title }}
      </div>
    </template>
    <div v-else class="home-head" @click="isMenuView = true">
      <img class="home-logo" src="../assets/imgs/logo.png" alt="" />
      <div class="home-head-title">
        <p class="home-head-title-small">Welcome to</p>
        <p class="home-head-title-big">{{ title }}</p>
      </div>
    </div>
    <div>
      <div class="home-warp" v-if="isScan && cabinet && !isBtns">
        <div class="banner" :style="banner"></div>
        <div class="home-warp-main">
          <div class="home-warp-block"></div>
          <div class="home-warp-cont">
            <div class="home-warp-title">
              <img src="../assets/imgs/map.png" alt="" />
              <span>{{ cabinet.shopname }}</span>
            </div>
            <div class="home-warp-jifei">
              <span>{{ cabinet.currencyName }}{{ cabinet.jifei }}</span
              >/{{ cabinet.jifeiDanwei }}{{ i18n.minute }}
            </div>
            <div class="home-warp-row">{{ shopDesc }}</div>
            <div class="home-warp-row">
              <img src="../assets/imgs/icon_phone.png" alt="" />
              {{ i18n.feature_1 }}
            </div>
            <div class="home-warp-row">
              <img src="../assets/imgs/icon_star.png" alt="" />
              {{ i18n.feature_2 }}
            </div>
            <!-- <div class="home-warp-row">
              <img src="../assets/imgs/icon_tag.png" alt="">
              {{i18n.feature_3}}
            </div> -->
          </div>

          <div class="home-warp-btn">
            <div
              class="btn"
              v-if="cabinet.yajintype === 0 && cabinet.yajin > 0"
              @click="onSubmit"
            >
              {{ i18n.preauth }} {{ cabinet.currencyName }}{{ amount }}
              {{ cabinet.currency }}
            </div>
            <div v-else class="btn" @click="onSubmit">
              {{ i18n.borrow }} {{ cabinet.currencyName }}{{ amount }}
              {{ cabinet.currency }}
            </div>
          </div>
          <!-- <div class="home-warp-btn" v-else>
            <div class="home-warp-tips">
              <img src="../assets/imgs/icon_info.png" alt="" />
              <span>{{ shopTips3 }}</span>
            </div>
            <!- <div class="btn" @click="onBorrow">{{ i18n.borrow }}</div> ->
          </div> -->
        </div>
      </div>
      <div v-else>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#7ED957">
          <van-swipe-item v-for="(item, i) in swipeList" :key="i">
            <img v-lazy="item.imgPath" />
            <h3>{{ item.title }}</h3>
            <h4>{{ item.text }}</h4>
          </van-swipe-item>
        </van-swipe>
        <div class="home-foot">
          <div class="home-btns">
            <template v-if="!code">
              <a v-if="system === 'ios'" style="background: #000" href="">
                <img src="../assets/imgs/apple.png" alt="" />
                <span>App Store</span>
              </a>
              <a v-else style="background: #000" href="">
                <img src="../assets/imgs/google.png" alt="" />
                <span>Google Play</span>
              </a>
            </template>
            <div class="downloaddark" v-else>
              <div class="icondark">
                <img
                  src="../assets/imgs/appstore_dark.png"
                  @click="onAppStore"
                  alt=""
                />
                <img
                  src="../assets/imgs/googleplay_dark.png"
                  @click="onGooglePlay"
                  alt=""
                />
              </div>
              <div class="next" @click="onCode">
                <div class="text">NEXT</div>
                <img src="../assets/imgs/icon_next.png" alt="" />
              </div>
              <!-- <a class="next" v-if="code" @click="onCode">
                <span>{{i18n.goborrow}}</span>
              </a> -->
            </div>
          </div>
          <!-- <span class="nearby" @click="goNearby">{{i18n.nearby}} ></span> -->
        </div>
      </div>
      <popup-warp v-model="isMenuView" dialogTransition="slide-left">
        <menu-side></menu-side>
      </popup-warp>
    </div>
  </page>
</template>

<script>
import { PopupWarp } from "../components/popup"
import { MenuSide } from "../components/menu"
import { mapState } from "vuex"
import { QR_CODE, ZH_CODE, ORDER_TEMP } from "../apis/cabinet"
import { USER_INFO, USER_CARD } from "../apis/user"
import { SAVED_CARD_TO_RENT } from "../apis/payment"
export default {
  components: {
    PopupWarp,
    MenuSide
  },
  computed: {
    ...mapState(["qrcode", "zhcode", "cabinet", "browser", "user", "system"]),
    i18n() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    title() {
      return process.env.VUE_APP_NAME
    },
    code() {
      return this.qrcode || this.zhcode
    },
    scanUrl() {
      if (this.qrcode) {
        return `${QR_CODE}?id=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    banner() {
      return this.cabinet && this.cabinet.shoplogo
        ? `background-image: url(${this.cabinet.shoplogo});`
        : ""
    },
    shopDesc() {
      return `${this.i18n.price_info}`.format(this.cabinet)
    },
    shopTips1() {
      return `${this.i18n.tips_1}`.format(this.cabinet)
    },
    shopTips2() {
      return `${this.i18n.tips_2}`.format(this.cabinet)
    },
    shopTips3() {
      return `${this.i18n.tips_3}`.format(this.cabinet)
    },
    amount() {
      if (this.cabinet) {
        return (this.cabinet.yajin - this.cabinet.myyue).toFixed(2)
      } else {
        return 0
      }
    }
  },
  data() {
    const _this = this
    return {
      isMenuView: false,
      isScan: false,
      isBtns: false,
      checked: true,
      adVisible: false,
      swipeList: [
        {
          imgPath: require("../assets/imgs/step1.png"),
          title: _this.$t("home.swipetitle1"),
          text: _this.$t("home.swipemsg1")
        },
        {
          imgPath: require("../assets/imgs/step2.png"),
          title: _this.$t("home.swipetitle2"),
          text: _this.$t("home.swipemsg2")
        },
        {
          imgPath: require("../assets/imgs/step3.png"),
          title: _this.$t("home.swipetitle3"),
          text: _this.$t("home.swipemsg3")
        },
        {
          imgPath: require("../assets/imgs/step4.png"),
          title: _this.$t("home.swipetitle4"),
          text: _this.$t("home.swipemsg4")
        }
      ],
      cardList: [] // 支付的卡列表
    }
  },
  methods: {
    onScan() {
      if (!this.scanUrl) return
      this.$loading(true)
      this.$post(
        this.scanUrl,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.vieworder,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$router.push("/order")
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          } else {
            this.isScan = true
            this.isBtns = false
            this.adVisible = true
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onSubmit() {
      this.$store.commit("wallet", null)
      // yajin 押金金额  && overtimeAmount 超时金额
      const { yajin, overtimeAmount } = this.cabinet
      console.log(this.cabinet, yajin, overtimeAmount)
      // 余额租借 - 押金金额 = 0 && 超时金额 = 0
      if (yajin <= 0 && overtimeAmount <= 0) {
        return this.onBorrow()
      }

      let paymentWay = ""
      //  是否有卡
      var _isUserCard = this.cardList.length > 0
      // 卡授权 - 押金金额 = 0 && 超时金额 > 0
      if (yajin <= 0 && overtimeAmount > 0) {
        paymentWay = "PAYMENT_CARD"
        if (_isUserCard) {
          return this.saveToRent()
        }
      }
      // 预授权 - 押金金额 > 0 && 超时金额 > 0
      if (yajin > 0 && overtimeAmount > 0) {
        paymentWay = "PAYMENT_PROPAY"
        // 保存卡 - 押金金额 < 超时金额  （必须绑卡）
        if (yajin < overtimeAmount) {
          paymentWay = "PAYMENT_SAVED_PROPAY"
        }
      }
      this.$router.push({
        path: "/confirm",
        query: {
          paymentWay,
          payType: "YJ",
          price: this.amount,
          cabinetID: this.cabinet.cabinetID,
          yaJinType: this.cabinet.yajintype
        }
      })
    },
    onBorrow() {
      this.$loading(true)
      this.$get(
        // `${ORDER_TEMP}/${this.cabinet.qrcode}/paypal`,
        `${ORDER_TEMP}?qrcode=${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data.outTradNo) {
            this.$router.push(
              `/result?tradeNo=${resp.data.outTradNo}&shopid=${this.cabinet.shopid}`
            )
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        }
      )
    },
    loadUser() {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
            this.onScan()
          } else {
            this.$store.commit("user", null)
          }
        },
        (error) => {
          this.$store.commit("user", null)
          console.log(error)
        }
      )
    },
    onAppStore() {
      console.log("11111")
      window.location.href = process.env.VUE_APP_APP_STORE
    },
    onGooglePlay() {
      console.log("22222")
      window.location.href = process.env.VUE_APP_GOOGLE_PLAY
    },
    onCode() {
      this.isBtns = false
      this.loadUser()
    },
    onEntrust() {
      console.log("test", process.env.VUE_APP_DOMAIN_NAME)
      window.location.href = `${process.env.VUE_APP_DOMAIN_NAME}/content/wxf_auth.html`
    },
    goNearby() {
      this.$router.push("./google_map")
    },
    setDocumentTitle(title) {
      document.title = title
      if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
        var i = document.createElement("iframe")
        i.src = "/favicon.ico"
        i.style.display = "none"
        i.onload = function () {
          setTimeout(function () {
            i.remove()
          }, 9)
        }
        document.body.appendChild(i)
      }
    },
    // 获取用户支付卡信息
    async getUserPayCard() {
      this.$loading(true)
      const qrcode = this.qrcode || this.cabinet.qrcode
      const { data = [] } = await this.$Request("get", USER_CARD, {
        qrcode,
        orderId: "",
        agentld: this.agentOpenId
      })
      let cardList = []
      if (data && data.length) {
        cardList = data.map((item) => {
          return {
            phone: "all",
            label: item.pCardnumber,
            ...item.paymentConfig,
            ...item
          }
        })
        this.cardList = cardList
      }
      this.$loading(false)
      return cardList
    },
    saveToRent() {
      this.$loading(true)
      this.$get(
        `${SAVED_CARD_TO_RENT}`,
        {
          qrcode: this.cabinet.cabinetID || this.$route.query.qrcode
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data && resp.data.out_trade_no) {
            this.$router.replace(
              `/result?&payMode=${this.payMode}&tradeNo=${resp.data.out_trade_no}`
            )
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    }
  },
  created() {
    console.log("类型", this.browser, "1")
    this.isBtns = true
    if (!this.cabinet) {
      this.$router.go(-1)
    } else if (this.$route.query && this.$route.query.cabinetshow === "true") {
      this.isScan = true
      this.isBtns = false
      this.adVisible = true
    }
    // this.loadUser()
    // if (!this.isBtns && this.code) {
    //   this.loadUser()
    // }
  },
  mounted() {
    this.getUserPayCard()
  }
}
</script>

<style lang="less">
.home-head {
  width: 100%;
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // margin: 20px 0 0;
  padding: 46px 60px;
  .home-logo {
    width: 300px;
  }
  &-title {
    p {
      text-align: end;
      margin: 10px 0;
    }
    &-small {
      font-family: "Montserrat-Medium";
      font-size: 24px;
      color: #171917;
    }
    &-big {
      font-family: "Montserrat-SemiBold";
      font-size: 36px;
      font-weight: 500;
      color: #000;
    }
  }
}
.home-warp {
  .banner {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #fff;
    background: url("../assets/imgs/banner-home.jpg") no-repeat center;
    background-size: cover;
  }
  &-main {
    width: 100%;
    height: calc(100vh - 454px);
    position: fixed;
    bottom: 0;
    left: 0;
    background: #ffffff;
    border-radius: 40px 40px 0 0;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }
  &-block {
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: " ";
      height: 8px;
      width: 80px;
      margin: 20px;
      border-radius: 4px;
      background: #cccccc;
    }
  }
  &-cont {
    padding: 0 48px;
  }
  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  &-jifei {
    padding: 60px 0 20px;
    font-size: 36px;
    font-weight: 600;
    span {
      font-size: 60px;
    }
  }
  &-row {
    display: flex;
    font-size: 24px;
    line-height: 32px;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
    color: #999999;
    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
  }
  &-btn {
    position: fixed;
    left: 48px;
    right: 48px;
    bottom: 48px;
    button {
      width: 100%;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  &-tips {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    font-size: 28px;
    color: #666666;
    padding: 20px 0;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
.home-bg {
  width: 60%;
  display: block;
  margin: 0px auto;
  // height: 100vh;
}
.my-swipe .van-swipe-item {
  img {
    display: block;
    width: 65%;
    margin: 0 auto;
  }
  h3,
  h4 {
    text-align: center;
  }
}
.home-btns {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 40px;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  z-index: -1;
  a {
    text-decoration: none;
    width: 30%;
    flex: 1;
    margin: 20px;
    background: #000000;
    color: #ffffff;
    padding: 20px 0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
.downloaddark {
  display: flex;
  justify-content: space-between;
  margin: 0 60px;
  .icondark {
    width: 40%;
    img {
      display: block;
      width: 90%;
    }
    img:last-child {
      margin-top: 20px;
    }
  }
  .next {
    width: 40%;
    font-family: "DIN Condensed Bold";
    font-size: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #73d146;
    border-radius: 20px;
    padding: 0px 20px;
    .text {
      // line-height: 64px;
      display: block;
    }
    img {
      width: 64px;
      height: 64px;
      margin-left: 20px;
      vertical-align: middle;
    }
  }
}
.go-code {
  text-align: center;
  color: #67c23a;
  text-decoration: underline;
  padding: 10px 0 30px;
}
.home-foot {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  .nearby {
    position: absolute;
    bottom: 170px;
    right: 20px;
    color: #fff;
    text-decoration: underline;
    text-align: center;
  }
}
.borrow-wechat-zff {
  display: flex;
  justify-content: center;
  padding: 20px 0 4px;
  img {
    height: 34px;
  }
}
.home-checkbox {
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  .home-checkbox-text {
    color: #52ac7a;
  }
  input[type="checkbox"] {
    cursor: pointer;
    position: relative;
  }
  input[type="checkbox"]::after {
    position: absolute;
    top: 0;
    background-color: #fff;
    color: #fff;
    width: 28px;
    height: 28px;
    display: inline-block;
    visibility: visible;
    padding-left: 0px;
    text-align: center;
    content: " ";
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid #ddd;
  }

  input[type="checkbox"]:checked::after {
    content: "";
    background-color: #52ac7a;
    border-color: #52ac7a;
    background-color: #52ac7a;
  }

  input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 10px;
    width: 6px;
    height: 16px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    z-index: 1;
  }
}
.home-mine {
  width: 90px;
  height: 60px;
  line-height: 30px;
  padding: 15px 0;
  font-size: 28px;
  text-align: center;
  position: fixed;
  right: 40px;
  bottom: 350px;
  z-index: 99;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 8px 24px 0 rgba(52, 125, 255, 0.32);
  // img {
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 50%;
  // }
}
</style>
